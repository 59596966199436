// components/Header.tsx
import React from 'react';
import { motion } from 'framer-motion';

const Header: React.FC = () => {
  return (
    <header className="bg-white py-4 md:py-6">
      <motion.div
        className="container mx-auto flex flex-col items-center md:flex-row md:justify-between px-4"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="flex items-center mb-4 md:mb-0">
          <motion.img
            src="/images/logoanalise.png" // Substitua pelo caminho correto
            alt="Ícone"
            className="mr-4 w-10 h-10"
            initial={{ rotate: 0 }}
            animate={{ rotate: 90 }}
            transition={{ duration: 0.5 }}
          />
          <h1 className="text-center font-semibold text-base md:text-lg bg-gradient-to-r from-blue-600 to-blue-400 bg-clip-text text-transparent">
            ANÁLISE SERVIÇOS CONTÁBEIS<br/>BELO HORIZONTE/MG - BRASIL
          </h1>
        </div>
        <a
          href="https://wa.me/5531971418588"
          target="_blank"
          rel="noopener noreferrer"
          className="mt-4 md:mt-0 bg-gradient-to-r from-blue-600 to-blue-400 text-white font-semibold py-2 px-6 rounded hover:shadow-lg hover:scale-105 transition-transform duration-300"
        >
          Entre em contato
        </a>
      </motion.div>
    </header>
  );
};

export default Header;
