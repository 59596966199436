// App.tsx
import React from 'react';
import Header from './components/Header';
import Hero from './sections/Hero';
import HeroImage from './sections/HeroImage';
import Services from './sections/Services';
import WhyChooseUs from './sections/WhyChooseUs';
import Contact from './sections/Contact';
import MapAndHours from './sections/MapAndHours';
import Footer from './components/Footer';

function App() {
  return (
    <div>
      <Header />
      <main>
        <Hero />
        <HeroImage />
        <Services />
        <WhyChooseUs />
        <Contact />
        <MapAndHours />
      </main>
      <Footer />
    </div>
  );
}

export default App;
