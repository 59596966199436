// sections/HeroImage.tsx
import React from 'react';
import { motion } from 'framer-motion';

const HeroImage: React.FC = () => {
  return (
    <section className="py-8 md:py-4">
      <div className="container mx-auto px-4 md:px-8">
        <motion.div
          className="overflow-hidden rounded-lg shadow-lg"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: 'easeOut' }}
        >
          <img
            decoding="async"
            sizes="1200px"
            srcSet="
              https://framerusercontent.com/images/26kCWILn6L0UfJollKNvN6aENDk.png?scale-down-to=512 512w, 
              https://framerusercontent.com/images/26kCWILn6L0UfJollKNvN6aENDk.png?scale-down-to=1024 1024w, 
              https://framerusercontent.com/images/26kCWILn6L0UfJollKNvN6aENDk.png 1360w
            "
            src="https://framerusercontent.com/images/26kCWILn6L0UfJollKNvN6aENDk.png"
            alt="Imagem Principal"
            className="w-full h-full object-cover"
            style={{ display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center center', objectFit: 'cover', imageRendering: 'auto' }}
          />
        </motion.div>
      </div>
    </section>
  );
};

export default HeroImage;
