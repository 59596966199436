// sections/WhyChooseUs.tsx
import React from 'react';
import { motion } from 'framer-motion';

// List of reasons to choose the service, including title, description, and highlights for each reason
const reasons = [
  {
    title: 'Experiência sólida',
    description: 'Estamos no mercado desde Agosto de 1990. Mais de 30 anos de evolução e conhecimento.',
    highlight: '30+',
    subHighlight: 'Anos de experiência',
  },
  {
    title: 'Clientes Satisfeitos',
    description: 'Já ajudamos mais de 1.000 clientes a alcançarem seus objetivos.',
    highlight: '1000+',
    subHighlight: 'Clientes satisfeitos',
  },
  {
    title: 'Atendimento personalizado',
    description: 'Entendemos que cada cliente é único. Nosso objetivo é que suas necessidades sejam atendidas de forma integral e personalizada.',
    highlight: '100%',
    subHighlight: 'Foco no cliente',
  },
];

// Functional component for the WhyChooseUs section
const WhyChooseUs: React.FC = () => {
  return (
    <section className="py-8 md:py-16 bg-gray-50 text-center">
      <div className="container mx-auto px-4 md:px-8">
        {/* Animated heading for the section */}
        <motion.h2
          className="text-3xl md:text-4xl font-semibold mb-4 bg-gradient-to-r from-blue-600 to-blue-400 bg-clip-text text-transparent"
          initial={{ opacity: 0 }} // Initial state of the element (invisible)
          animate={{ opacity: 1 }} // Final state of the element (fully visible)
          transition={{ duration: 0.5 }} // Duration of the animation
        >
          Por que nos escolher?
        </motion.h2>
        {/* Animated subheading paragraph */}
        <motion.p
          className="text-lg md:text-xl mb-8 text-gray-700"
          initial={{ opacity: 0 }} // Initial state of the paragraph (invisible)
          animate={{ opacity: 1 }} // Final state of the paragraph (fully visible)
          transition={{ delay: 0.2, duration: 0.5 }} // Delay before the animation starts
        >
          Veja alguns dos vários motivos
        </motion.p>
        {/* Container for the list of reasons */}
        <div className="flex flex-wrap justify-center gap-8">
          {reasons.map((reason, index) => (
            // Animated card for each reason
            <motion.div
              key={index}
              className="w-full md:w-1/3 lg:w-1/4 p-4 bg-white rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 transform hover:scale-105"
              initial={{ opacity: 0, y: 50 }} // Initial state: slightly below and invisible
              animate={{ opacity: 1, y: 0 }} // Final state: visible and at its normal position
              transition={{ delay: 0.3 + index * 0.2, duration: 0.5 }} // Delay based on index to create a staggered effect
            >
              <div className="flex flex-col items-center">
                {/* Container for the highlight section with a gradient background and SVG wave */}
                <div className="relative w-32 h-20 rounded-lg overflow-hidden shadow-md p-4 bg-gradient-to-r from-green-400 to-blue-500 hover:from-blue-500 hover:to-green-400 transition-colors duration-500 box-border">
                  {/* SVG for decorative wave effect */}
                  <svg
                    className="absolute top-0 left-0 w-full h-full"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 300 120"
                    preserveAspectRatio="none"
                  >
                    <defs>
                      {/* Define a gradient for the wave */}
                      <linearGradient id="waveGradient" x1="0.5" x2="0.5" y1="0" y2="1">
                        <stop offset="0%" stopColor="rgb(255, 255, 255)" stopOpacity="1" />
                        <stop offset="100%" stopColor="hsla(200, 75%, 60%, 0.3)" stopOpacity="0.3" />
                      </linearGradient>
                    </defs>
                    <path
                      d="M 0 60 Q 50 20 100 60 T 200 60 T 300 60 V 120 H 0 Z"
                      fill="url(#waveGradient)" // Fill path with the defined gradient
                      strokeWidth="0.5"
                      stroke="rgb(210, 210, 210)"
                      strokeMiterlimit="10"
                    />
                  </svg>
                  {/* Highlight text (e.g., '30+' and 'Anos de experiência') */}
                  <div className="absolute inset-0 flex flex-col justify-center items-center p-4 box-border">
                    <p className="text-2xl font-bold text-white drop-shadow-lg">{reason.highlight}</p>
                    <p className="text-sm text-yellow-200 drop-shadow-md">{reason.subHighlight}</p>
                  </div>
                </div>
              </div>
              {/* Title and description of the reason */}
              <h3 className="text-xl md:text-2xl font-medium mb-2 text-blue-600">{reason.title}</h3>
              <p className="text-gray-600">{reason.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
