// sections/MapAndHours.tsx
import React from 'react';
import { motion } from 'framer-motion';
import { FaPhoneAlt, FaWhatsapp, FaEnvelope } from 'react-icons/fa';

const MapAndHours: React.FC = () => {
  return (
    <section className="px-4 md:px-8 py-8 flex flex-col">
      <div className="w-full mx-auto md:w-4/5 lg:w-4/5">
        {/* Animated container for the map and contact information */}
        <div className="mt-8 flex flex-col md:flex-row gap-8 items-center md:items-start">

          {/* Contact Information Section - occupies 40% width on medium and larger screens */}
          <motion.div
            className="w-full md:w-2/5 flex flex-col gap-2 text-center md:text-left"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
            viewport={{ once: true }}
          >
            {/* Business Location and Hours Heading */}
            <h2 className="text-3xl font-semibold mb-4 bg-gradient-to-r from-blue-600 to-blue-400 bg-clip-text text-transparent">
              Localização e Horário de funcionamento
            </h2>

            {/* Address Information */}
            <p className="mb-4">
              R. Jacuí, 3930<br />
              Bairro: Ipiranga<br />
              Belo Horizonte - MG<br />
              CEP 31160-190
            </p>


            {/* Contact Numbers */}
            <div className="space-y-4">
              {/* Telephone 1 */}
              <a href="tel:+553134842711" className="flex items-center justify-center md:justify-start gap-2 hover:text-[#CFB354] transition-colors">
                <FaPhoneAlt className="text-blue-500" />
                <span className="text-blue-500 font-semibold">(31) 3484-2711</span>
              </a>
              {/* Telephone 2 */}
              <a href="tel:+5531996951900" className="flex items-center justify-center md:justify-start gap-2 hover:text-[#CFB354] transition-colors">
                <FaPhoneAlt className="text-blue-500" />
                <span className="text-blue-500 font-semibold">(31) 99695-1900</span>
              </a>
              {/* WhatsApp */}
              <a href="https://wa.me/5531971418588" className="flex items-center justify-center md:justify-start gap-2 hover:text-[#CFB354] transition-colors">
                <FaWhatsapp className="text-green-500" />
                <span className="text-blue-500 font-semibold">(31) 97141-8588</span>
              </a>
              {/* Email */}
              <a href="mailto:analise@analisecont.com.br" className="flex items-center justify-center md:justify-start gap-2 hover:text-[#CFB354] transition-colors">
                <FaEnvelope className="text-yellow-500" />
                <span className="text-blue-500 font-semibold">analise@analisecont.com.br</span>
              </a>
            </div>

            {/* Business Hours Table - Improved layout for desktop and mobile */}
            <div className="mt-4">
              <h3 className="text-xl font-semibold text-black mb-2">Horário de funcionamento</h3>
              <table className="w-full text-center md:text-left border-collapse">
                <thead>
                  <tr>
                    <th className="border-b-2 border-[#FDD963] pb-2">Dia</th>
                    <th className="border-b-2 border-[#FDD963] pb-2">Horário</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="py-2">Segunda a Sexta</td>
                    <td className="py-2">8:00 às 17:00</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </motion.div>

          {/* Map Section - occupies 60% width on medium and larger screens */}
          <motion.div
            className="w-full md:w-3/5 h-[50vh]"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <iframe
              src="https://maps.google.com/maps?q=-19.879153770145486,-43.93071737447302&z=16&output=embed"
              width="100%"
              height="100%"
              style={{ border: 0, borderRadius: '0.5rem' }}
              allowFullScreen
              loading="lazy"
              title="Localização da RKE Sondagem e Perfurações LTDA"
            ></iframe>
          </motion.div>
        </div>
      </div>
    </section >
  );
};

export default MapAndHours;
