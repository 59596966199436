// sections/Contact.tsx
import React from 'react';
import { motion } from 'framer-motion';
import { sendEmail } from '../utils/emailjs';
import { useRef, useState, useEffect } from 'react'; // Certifique-se de incluir useEffect aqui

const Contact: React.FC = () => {

  const form = useRef<HTMLFormElement>(null);
  const [isSending, setIsSending] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [formStartTime, setFormStartTime] = useState<number>(Date.now());

  useEffect(() => {
    setFormStartTime(Date.now());
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsSending(true); // Ativa o estado de envio

    const timeTaken = Date.now() - formStartTime;

    if (timeTaken < 3000) { // Se o formulário for enviado em menos de 3 segundos
      setMessage('Preencha o formulário corretamente.');
      setError(true);
      setIsSending(false); // Desativa o estado de envio
      return;
    }

    if (form.current) {
      const honeypot = form.current.querySelector<HTMLInputElement>('input[name="honeypot"]');
      if (honeypot?.value) {
        setMessage('Algo deu errado... Use o botão do Whatsapp para contato.'); // Mensagem para bots
        setError(true);
        setIsSending(false); // Desativa o estado de envio
        return;
      }

      // Processar o envio normal
      try {
        await sendEmail(form.current);
        setMessage('Mensagem enviada com sucesso! Em breve entraremos em contato.');
        setError(false);
        form.current.reset();
      } catch (error) {
        console.error('Erro ao enviar mensagem', error);
        setMessage('Erro ao enviar mensagem. Tente novamente.');
        setError(true);
      } finally {
        setIsSending(false); // Desativa o estado de envio
      }
    }
  };

  return (
    <section className="px-4 md:px-8 py-12 bg-white">
      <motion.div
        className="text-center mb-8"
        initial={{ opacity: 0, y: -20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        viewport={{ once: true }}
      >
        <h2 className="text-4xl font-bold bg-gradient-to-r from-blue-600 to-blue-400 bg-clip-text text-transparent">
          Entre em<br />contato
        </h2>
      </motion.div>
      <motion.div
        className="max-w-xl mx-auto"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.2 }}
        viewport={{ once: true }}
      >
        <form ref={form} onSubmit={handleSubmit} className="space-y-6">
          {/* Campo Honeypot (oculto) */}
          <input type="text" name="honeypot" style={{ display: 'none' }} />

          {/* Linha 1: Nome (ocupa toda a linha) */}
          <div>
            <input
              type="text"
              name="name"
              placeholder="Nome"
              required
              className="w-full px-4 py-2 rounded-md bg-[#EBEBEB] text-gray-800 focus:outline-none focus:ring-2 focus:ring-[#FDD963]"
            />
          </div>

          {/* Linha 2: Email e Telefone (cada um ocupa metade da linha) */}
          <div className="flex flex-col md:flex-row gap-4">
            <input
              type="email"
              name="email"
              placeholder="Email"
              required
              className="w-full md:w-1/2 px-4 py-2 rounded-md bg-[#EBEBEB] text-gray-800 focus:outline-none focus:ring-2 focus:ring-[#FDD963]"
            />
            <input
              type="tel"
              name="phone"
              placeholder="Telefone"
              required
              className="w-full md:w-1/2 px-4 py-2 rounded-md bg-[#EBEBEB] text-gray-800 focus:outline-none focus:ring-2 focus:ring-[#FDD963]"
            />
          </div>

          {/* Linha 3: Mensagem */}
          <div>
            <textarea
              name="message"
              placeholder="Mensagem"
              required
              className="w-full px-4 py-2 h-32 rounded-md bg-[#EBEBEB] text-gray-800 focus:outline-none focus:ring-2 focus:ring-[#FDD963] resize-none"
            ></textarea>
          </div>

          <div className="text-center">
            <button
              type="submit"
              disabled={isSending}
              className="px-6 py-2 rounded-md bg-gradient-to-r from-blue-600 to-blue-400 text-white font-semibold hover:from-blue-500 hover:to-blue-300 transition-colors"
            >
              {isSending ? 'Enviando...' : 'Enviar'}
            </button>
          </div>

          {/* Mensagem de Sucesso ou Erro */}
          {message && (
            <p className={`text-center mt-4 ${error ? 'text-red-500' : 'text-green-500'}`}>
              {message}
            </p>
          )}
        </form>


      </motion.div>
    </section>
  );
};

export default Contact;
