// components/Footer.tsx
import React from 'react';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gradient-to-r from-gray-800 to-gray-600 py-4 text-white text-center">
      <div className="container mx-auto px-4">
      <p>© {currentYear} Análise Serviços Contábeis</p>
        <p>CNPJ: 38.743.571/0001-99</p>
        <p>E-mail: <a href="mailto:analise@analisecont.com.br" className="text-yellow-400 hover:underline">analise@analisecont.com.br</a></p>
        <p>Telefone: <a href="tel:3134842711" className="text-yellow-400 hover:underline">(31) 3484-2711</a></p>        
        <p className="text-sm text-gray-400 mt-2">Desenvolvido por <a href="https://www.horizonteinteligencia.com.br" className="text-gray-300 hover:underline">Horizonte Tecnologia</a></p>
      </div>
    </footer>
  );
};

export default Footer;
