// sections/Services.tsx
import React from 'react';
import { motion } from 'framer-motion';
import { FaFileInvoiceDollar, FaBalanceScale, FaSearch, FaCalculator, FaBusinessTime, FaWhatsapp } from 'react-icons/fa';

// Define a list of services with corresponding icons from react-icons
const services = [
  { name: 'IRPF', icon: <FaFileInvoiceDollar className="text-blue-500 w-8 h-8 mb-4" /> },
  { name: 'Demonstrações Contábeis', icon: <FaBalanceScale className="text-green-500 w-8 h-8 mb-4" /> },
  { name: 'Pesquisa de Pendências em CNPJ', icon: <FaSearch className="text-yellow-500 w-8 h-8 mb-4" /> },
  { name: 'Cálculo de Impostos Federais, Estaduais e Municipais', icon: <FaCalculator className="text-red-500 w-8 h-8 mb-4" /> },
  { name: 'Consultoria Empresarial', icon: <FaBusinessTime className="text-purple-500 w-8 h-8 mb-4" /> },
  { name: 'E muito mais. Entre em contato conosco!', icon: <FaWhatsapp className="text-green-500 w-8 h-8 mb-4" /> },
];

const Services: React.FC = () => {
  return (
    <section className="py-16 md:py-16 bg-gray-50">
      <div className="container mx-auto px-4 md:px-8">
        <motion.h2
          className="text-3xl md:text-4xl font-semibold text-center mb-8 bg-gradient-to-r from-blue-600 to-blue-400 bg-clip-text text-transparent"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Liberdade Financeira, Simplificada.
        </motion.h2>
        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          {services.map((service, index) => (
            <motion.div
              key={index}
              className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
              whileHover={{ scale: 1.05 }}
            >
              {/* Render the icon for each service */}
              <div className="flex justify-center">{service.icon}</div>
              <h3 className="text-xl font-semibold text-center mb-4 mt-2">{service.name}</h3>
              <p className="text-center text-gray-600">
                {service.name === 'E muito mais. Entre em contato conosco!' ? (
                  'Descubra como podemos ajudar você e sua empresa a alcançar o sucesso.'
                ) : (
                  'Conte com nossa expertise para solucionar suas necessidades contábeis.'
                )}
              </p>
            </motion.div>
          ))}
        </motion.div>
        <motion.div
          className="flex justify-center mt-12"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          <a
            href="https://wa.me/5531971418588"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-gradient-to-r from-green-500 to-green-400 text-white font-semibold py-3 px-6 rounded-full flex items-center hover:shadow-lg hover:scale-105 transition-transform duration-300"
          >
            {/* Use react-icon for WhatsApp instead of an image */}
            <FaWhatsapp className="w-6 h-6 mr-3" />
            Fale Conosco
          </a>
        </motion.div>
      </div>
    </section>
  );
};

export default Services;
