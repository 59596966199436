// src/utils/emailjs.ts
import emailjs from 'emailjs-com';

export const sendEmail = (form: HTMLFormElement) => {
  return emailjs.sendForm(
    'service_i5ebg5e', // Substitua com seu service ID
    'template_pw0kyuu', // Substitua com seu template ID
    form,
    'uk-Fs_zE9A23ydRfd' // Substitua com seu user ID
  );
};
