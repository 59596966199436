// sections/Hero.tsx
import React from 'react';
import { motion } from 'framer-motion';

const Hero: React.FC = () => {
  return (
    <section className="py-4 md:py-16 text-center">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <p className="text-2xl font-medium mb-4">
            Foque na sua empresa e deixe a contabilidade conosco.
          </p>
          <p className="text-xl">
            Somos especialistas em fornecer soluções inovadoras, eficientes e personalizadas para atender suas necessidades.
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default Hero;
